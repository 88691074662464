<script lang="ts" setup>
import BannerVideo from './components/BannerVideo.vue'
import Headerbar from '@/components/Header/Headerbar.vue'
import About from './components/About.vue'
import Section1 from './components/Section1.vue'
import Section2 from './components/Section2.vue'
import Section3 from './components/Section3.vue'
import Section4 from './components/Section4.vue'
import FooterBar from '@/components/Footer/FooterBar.vue'
import Copyright from '@/components/Footer/Copyright.vue'
import { usePageStore } from '@/stores'
import useSeo from './seo'

definePageMeta({
  layout: 'home'
})

const { advert } = useApi()
const advertRes = ref(null)
// const advertRes = computed(() => advertData.value?.data)

const bottomStyle: Ref<number> = ref(0)

//  ref
const refHomePageVideo = ref()
const refHomePageHeader = ref()
const refHeaderbar = ref()
const refHomePageAbout = ref()
const refHomePageSection1 = ref()
const refHomePageSection2 = ref()
const refHomePageSection3 = ref()
const refHomePageSection4 = ref()

const domVideoHeight: Ref<number> = ref(0)
const domHeaderHeight: Ref<number> = ref(0)

const isFixed: Ref<boolean> = ref(false) // header fixed

const currentBgColor: Ref<string> = ref('#f2f2f2') // 當前背景色
const currentBgOpacity: Ref<number> = ref(1) // 當前背景透明度

// scroll 事件
const handleScroll = (data: any) => {
  handleSetValue()
  let _scrollTop = document.documentElement.scrollTop || document.body.scrollTop
  // console.log(_scrollTop)
  if (_scrollTop > domVideoHeight.value && isFixed.value === false) {
    isFixed.value = true
    refHeaderbar.value.setFixed(true)
    bottomStyle.value = domHeaderHeight.value
  } else if (_scrollTop < domVideoHeight.value && isFixed.value) {
    isFixed.value = false
    refHeaderbar.value.setFixed(false)
    bottomStyle.value = 0
  }

  //change color
  let sectionTop1 = Math.floor(
    Math.abs(
      refHomePageSection1.value.getBoundingClientRect().top +
      window.scrollY -
      window.innerHeight / 2
    )
  )
  let sectionTop2 = Math.floor(
    Math.abs(
      refHomePageSection2.value.getBoundingClientRect().top +
      window.scrollY -
      window.innerHeight / 2
    )
  )
  let sectionTop3 = Math.floor(
    Math.abs(
      refHomePageSection3.value.getBoundingClientRect().top +
      window.scrollY -
      window.innerHeight / 2
    )
  )
  let sectionTop4 = Math.floor(
    Math.abs(
      refHomePageSection4.value.getBoundingClientRect().top +
      window.scrollY -
      window.innerHeight / 2
    )
  )
  if (_scrollTop > sectionTop4) {
    currentBgColor.value = '#f2f2f2'
    currentBgOpacity.value = 1
  } else if (_scrollTop > sectionTop3) {
    currentBgColor.value = '#9ba8bb'
    currentBgOpacity.value = 0.3
  } else if (_scrollTop > sectionTop2) {
    currentBgColor.value = '#f2f2f2'
    currentBgOpacity.value = 1
  } else if (_scrollTop > sectionTop1) {
    currentBgColor.value = '#cbc5bc'
    currentBgOpacity.value = 0.4
  } else {
    currentBgColor.value = '#f2f2f2'
    currentBgOpacity.value = 1
  }
}

// 設定數值
const handleSetValue = () => {
  domVideoHeight.value = refHomePageVideo.value.clientHeight
  domHeaderHeight.value = refHomePageHeader.value.getBoundingClientRect().height
}

// component key
const componentKey: Ref<number> = ref(1)
const componentPrevKey: Ref<number> = ref(0)

const homeHeight: Ref<any> = ref(0)

onMounted(() => {
  homeHeight.value = window.innerHeight + 'px'
  setTimeout(async () => {
    homeHeight.value = 'auto'
    const { data: advertData } = await advert.getAdvert('all-site')
    advertRes.value = advertData.value?.data
  }, 2500)
  handleSetValue()
  document.addEventListener('scroll', handleScroll)
  // 一進頁面到頁首
  window.scrollTo(0, 0)
  window.addEventListener('resize', () => {
    // 解決 paralax breakpoint問題，當畫面 大小於 990 重新渲染組件
    if (window.innerWidth > 990) {
      if (componentKey.value != componentPrevKey.value) {
        componentKey.value++
        componentPrevKey.value = componentKey.value
      }
    } else {
      if (componentKey.value === componentPrevKey.value) {
        componentKey.value--
      }
    }
  })
})

onUnmounted(() => {
  document.removeEventListener('scroll', handleScroll)
})

const pageStore = usePageStore()
const { homeSeo } = pageStore
useSeo(homeSeo)
</script>

<template>
  <div class="home-page" :style="{ height: homeHeight }">
    <div class="home-page__video" ref="refHomePageVideo">
      <BannerVideo />
    </div>
    <div class="home-page__header" ref="refHomePageHeader">
      <Headerbar ref="refHeaderbar" />
    </div>
    <div class="home-page__bottom" :style="{ paddingTop: bottomStyle + 'px' }">
      <div class="home-page__about" ref="refHomePageAbout">
        <About :key="componentKey" />
      </div>
      <div class="home-page__section1" ref="refHomePageSection1">
        <Section1 :key="componentKey" :bgColor="currentBgColor" />
      </div>
      <div class="home-page__section2" ref="refHomePageSection2">
        <Section2 :key="componentKey" :bgColor="currentBgColor" />
      </div>
      <div class="home-page__section3" ref="refHomePageSection3">
        <Section3 :key="componentKey" :bgColor="currentBgColor" />
      </div>
      <div class="home-page__section4" ref="refHomePageSection4">
        <Section4 :key="componentKey" :bgColor="currentBgColor" />
      </div>
      <FooterBar />
      <Copyright />
      <DialogCover :data="advertRes" v-if="advertRes" />
      <div class="home-page__bg" :style="{ backgroundColor: currentBgColor, opacity: currentBgOpacity }"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.section-box {
  padding-top: toRem(110);
  padding-bottom: toRem(110);

  @include max-media(990) {
    padding-top: toRem(50);
    padding-bottom: toRem(50);
  }
}

.home-page {
  overflow: hidden;

  &__bottom {
    position: relative;
  }

  &__bg {
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: $color-white-2;
    transition: all 0.5s ease-in-out;
  }
}
</style>
